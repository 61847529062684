import api from '@/api/projects'
import {notify} from "@/helpers/notifyHelper"

export default {
	name: 'AdminShow',
	data () {
		return {
			project: null
		}
	},
	created() {
		if (this.id) {
			api.getById(this, this.id)
				.then(response => {
					if (response.data.length) {
						this.project = response.data[0]
					}
				})
				.catch(err => {
					notify(self, 'error', err)
				})
		}
	},
	computed: {
		id () {
			return this.$route.params.id
		},
		images() {
			if (this.project?.images) {
				if (typeof this.project.images === 'string') {
					return JSON.parse(this.project.images)
				}
				return this.project.images
			}
			return []
		}
	}
}
